import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPageStaticAllService } from 'services/pages';
import { StaticAllData } from 'services/pages/types';
import {
  getHeaderFooterService,
  getGeneralService,
  getPageErrorService,
} from 'services/systems';
import {
  FooterDataTypes,
  GeneralDataTypes,
  HeaderDataTypes,
  HeaderFooterDataTypes,
  ErrorPageData
} from 'services/systems/types';

interface SystemState {
  footer?: FooterDataTypes;
  header?: HeaderDataTypes;
  system?: GeneralDataTypes;
  pageError?: ErrorPageData;
  language?: string;
  staticAll?: StaticAllData[];
  isLogoutWithConfirmed: boolean;
}

const initialState: SystemState = {
  language: undefined,
  footer: undefined,
  header: undefined,
  system: undefined,
  pageError: undefined,
  staticAll: undefined,
  isLogoutWithConfirmed: false
};

export const getHeaderFootersAction = createAsyncThunk<HeaderFooterDataTypes>(
  'systemReducer/getHeaderFooterAction',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getHeaderFooterService();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getPageErrorAsync = createAsyncThunk<ErrorPageData>('systemReducer/getPageError', async (_, { rejectWithValue }) => {
  try {
    const response = await getPageErrorService();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
},);

export const getSystemAction = createAsyncThunk<GeneralDataTypes>(
  'systemReducer/getSystem',
  async (_, { rejectWithValue }) => {
    try {
      return await getGeneralService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getStaticAllAction = createAsyncThunk<StaticAllData[]>(
  'systemReducer/getStaticAll',
  async (_, { rejectWithValue }) => {
    try {
      return await getPageStaticAllService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {
    setLanguage($state, action: PayloadAction<string>) {
      $state.language = action.payload;
    },
    setIsLogoutWithConfirmed($state, action: PayloadAction<boolean>) {
      $state.isLogoutWithConfirmed = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getHeaderFootersAction.fulfilled, ($state, action) => {
      $state.header = action.payload.header;
      $state.footer = action.payload.footer;
    });
    builder.addCase(getSystemAction.fulfilled, ($state, action) => {
      $state.system = action.payload;
    });
    builder.addCase(getPageErrorAsync.fulfilled, ($state, action) => {
      $state.pageError = action.payload;
    });
    builder.addCase(getStaticAllAction.fulfilled, ($state, action) => {
      $state.staticAll = action.payload;
    });
  },
});

export const {
  setLanguage,
  setIsLogoutWithConfirmed
} = systemSlice.actions;

export default systemSlice.reducer;
