import React from 'react';
import { Image, Typography } from 'tfc-components';

import disclaimer from 'assets/images/comming-soon-disclaimer.png';
import logo from 'assets/images/logo-hoitm.png';
import useWindowDimensions from 'hooks/useWindowDemensions';

const CommingSoon: React.FC = () => {
  const { height } = useWindowDimensions();

  return (
    <div className="p-commingSoon" style={{ height: `${height}px` }}>
      <div className="p-commingSoon_head">
        <div className="p-commingSoon_logo">
          <Image imgSrc={logo} alt="Hope" ratio={379 / 302} />
        </div>
        <div className="p-commingSoon_text">
          <Typography.Text fontweight="500" extendClasses="color-white">
            Khóa học chỉ dành riêng cho cán bộ y tế
          </Typography.Text>
        </div>
      </div>
      <div className="p-commingSoon_body" />
      <div className="p-commingSoon_disclaimer">
        <Image imgSrc={disclaimer} alt="disclaimer" ratio={866 / 119} />
      </div>
    </div>
  );
};

export default CommingSoon;
